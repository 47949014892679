<template>
  <div :class="headerClasses">
    <div
      v-if="isMainPage"
      class="sm-header__left-section"
    >
      <sm-header-menu
        v-if="menuItems.length"
        :items="menuItems"
      ></sm-header-menu>
    </div>
    <div
      v-else
      class="sm-header__left-section"
    >
      <div class="sm-header__left-content">
        <div class="sm-header__line">
          <sm-breadcrumbs :items="breadcrumbs"></sm-breadcrumbs>
        </div>
        <div class="sm-header__line">
          <a
            class="sm-header__back-button"
            @click.prevent="handleBackClick"
          >
            <sm-icon
              name="ChevronLeft"
              class="sm-header__icon"
              :size="24"
            />
          </a>
          <p class="sm-header__page-title">{{ pageTitle }}</p>
        </div>
      </div>
    </div>
    <div
      class="sm-header__profile"
      v-click-outside="handleClickOutside"
    >
      <div
        :class="profileIconWrapperClasses"
        @click="toggleDropdown"
      >
        <div class="sm-header__icon-container">
          <div class="sm-header__icon-white-border">
            <sm-icon name="User" />
          </div>
        </div>
      </div>
      <sm-profile-dropdown
        v-if="dropdownOpen"
        :userName="userName"
        :items="dropdownMenuItems"
        @click="handleDropdownItemClick"
      >
        <!-- <slot></slot> -->
        <template v-slot:item="{ item }">
          <sm-switch
            v-if="item.switcher"
            v-model="item.switcherValue"
            :small="true"
          ></sm-switch>
        </template>
      </sm-profile-dropdown>
    </div>
  </div>
</template>

<script lang="ts" setup>
// Vue
import { computed, ref, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';
import SmHeaderMenu from '@/components/layouts/header/SmHeaderMenu.vue';
import SmProfileDropdown from '@/components/layouts/header/SmProfileDropdown.vue';
import SmBreadcrumbs from '@/components/common/SmBreadcrumbs/SmBreadcrumbs.vue';
import SmSwitch from '@/components/common/inputs/SmSwitch.vue';

// Types
import { IHeaderProps } from '@/types/components/layout';
import { IDropdownMenuItemProps } from '@/types/components/headerMenu';
import { IBreadcrumbItem } from '@/types/components/breadcrumbs';
import { Class } from '@/types/common';

const props = withDefaults(
  defineProps<IHeaderProps>(),
  {
    userName: '',
    menuItems: () => [],
    dropdownMenuItems: () => [],
    isMainPage: false
  }
);

const { menuItems, userName, dropdownMenuItems, isMainPage } = toRefs(props);

const route = useRoute();
const router = useRouter();
const dropdownOpen = ref<boolean>(false);

const breadcrumbs = computed((): IBreadcrumbItem[] => {
  return route.matched.map((route) => {
    const title: string = route.meta.title as string;
    return {
      title,
      route: {
        name: route.name
      }
    };
  });
});

const pageTitle = computed(() => route.meta.title || '');

const headerClasses = computed((): Class => ({
  'sm-header': true,
  'sm-header--page-title-visible': !isMainPage,
}));
const profileIconWrapperClasses = computed((): Class => ({
  'sm-header__profile-icon-wrapper': true,
  'sm-header__profile-icon-wrapper--active': dropdownOpen.value,
}));

const emits = defineEmits<{
  (e: 'click', value: IDropdownMenuItemProps): void;
}>();

// Methods
const toggleDropdown = (): void => {
  dropdownOpen.value = !dropdownOpen.value;
};

const handleDropdownItemClick = (item: IDropdownMenuItemProps): void => {
  emits('click', item);
};

const handleClickOutside = (): void => {
  dropdownOpen.value = false;
};

const handleBackClick = (): void => {
  router.back();
}

</script>

<style lang="scss">
.sm-header {
  @include mini;
  background-color: var(--Background);
  width: 100%;
  padding: 0 var(--padding);
  border-bottom: 1px solid var(--BorderMinor);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 61px;
}

.sm-header--page-title-visible {
  height: 88px;
}

.sm-header__left-section {
  display: flex;
  align-items: center;
  height: 100%;
}

.sm-header__left-content {
  display: flex;
  flex-direction: column;
  gap: var(--gap-s);
}

.sm-header__line {
  display: flex;
  align-items: center;
}

.sm-header__address {
  @include micro;
  color: var(--Icon);
  margin: 0;
}

.sm-header__page-title {
  @include h2;
  margin: 0;
}

.sm-header__profile {
  position: relative;
}

.sm-header__icon-white-border {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--Secondary);
  border-radius: 50%;
  border: 2px solid var(--Background);
}

.sm-header__profile-icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sm-header__icon-container {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--Secondary);
  border-radius: 50%;
  position: relative;
  border: 2px solid var(--Background);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-bottom: 6px solid var(--Secondary);
  }
}

.sm-header__profile-icon-wrapper--active .sm-header__icon-container {
  border: 2px solid var(--Body);

  &::after {
    border-bottom: 6px solid var(--Body);
  }
}

.sm-header__back-button {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sm-header__icon {
  color: var(--Icon);
}
</style>
