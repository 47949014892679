// Modules
import { computed, ComputedRef, getCurrentInstance } from 'vue';

// Types
import { Class } from '@/types/common';
import { IInput, ICommonEmits } from '@/types/components/inputs';

interface IUseInputReturns {
  handleInput: () => void;
  handleKeydown: () => void;
  handleKeyup: () => void;
  handleKeypress: () => void;
  computedName: ComputedRef;
  computedId: ComputedRef;
  classList: ComputedRef;
  uid: ComputedRef;
}

export function useInput(props: IInput, emits?: ICommonEmits) {
  // Props

  const { name, error, disabled, id } = props;

  // Computed

  /**
   * uid компонента. 
   * Формируется на основе данных компонента.
   *
   * @return  {number}  uid компонента
   */
  const uid = computed((): number => {
    const componentInstance = getCurrentInstance();
    return componentInstance?.uid || 0;
  });

  /**
   * Значение атрибута id.
   * Формируется на основе uid компонента.
   *
   * @return  {string}  Значение id
   */
  const computedId = computed((): string => (
    id || `input-${uid.value}`
  ));

  /**
   * Значение атрибута name.
   * Формируется на основе uid компонента.
   *
   * @return  {string}  Значение name
   */
  const computedName = computed((): string => (
    name || `input-${uid.value}`
  ));

  /**
   * Массив классов стилизации компонента.
   * Формируется на основе переданных свойств компонента.
   *
   * @return  {Class[]} Массив классов
   */
  const classList = computed((): Class => ([
    { 'sm-input--error': !!error },
    { 'sm-input--disabled': !!disabled },
  ]));

  // Methods

  /**
   * Обрабочик ввода значения.
   *
   * @param   {Event}  e  Объект события ввода.
   *
   * @return  {void}
   */
  const handleInput = (e: Event): void => {
    if (!emits) return;

    const value = (e.target as HTMLInputElement).value;

    emits('update:modelValue', value);
    emits('input', value);
  }

  /**
   * Обработчик нажатия клавиши при вводе.
   *
   * @param   {Event}  e  Объект событи нажатия клавиши.
   *
   * @return  {void}
   */
  const handleKeydown = (e: Event): void => {
    if (!emits) return;

    const value = (e.target as HTMLInputElement).value;

    emits('update:modelValue', value);
    emits('keydown', value);
  }

  /**
   * Обработчик отжатия клавиши при вводе.
   *
   * @param   {Event}  e  Объект события отжатия клавиши.
   *
   * @return  {void}
   */
  const handleKeyup = (e: Event): void => {
    if (!emits) return;

    const value = (e.target as HTMLInputElement).value;

    emits('update:modelValue', value);
    emits('keyup', value);
  }

  /**
   * Обработчик зажатия клавиши при вводе.
   *
   * @param   {Event}  e  Объект события зажатия клавиши.
   *
   * @return  {void}
   */
  const handleKeypress = (e: Event): void => {
    if (!emits) return;
    
    const value = (e.target as HTMLInputElement).value;

    emits('update:modelValue', value);
    emits('keypress', value);
  }

  return <IUseInputReturns> {
    handleInput,
    handleKeydown,
    handleKeyup,
    handleKeypress,
    computedName,
    classList,
    uid,
    computedId,
  }
}