<template>
  <div
    class="sm-switcher"
    :class="classList"
  >
    <div class="sm-switcher__control-wrap">
      <div
        role="switch"
        :aria-checked="modelValue"
        class="sm-switcher__track"
        :tabindex="tabindex"
        :aria-labelledby="computedId"
        @click="handleSwitch"
        @keypress.space="handleSwitch"
      >
        <span class="sm-switcher__control">
          <sm-icon
            v-if="showIcon"
            :name="iconName"
            size="16"
            class="sm-switcher__icon"
          />
        </span>
      </div>

      <label
        v-if="label"
        :for="computedId"
        class="sm-switcher__label"
        @click="handleSwitch"
      >
        {{ label }}
      </label>
    </div>

    <p
      v-if="hint"
      class="sm-switcher__hint"
    >{{ hint }}</p>
  </div>
</template>

<script lang="ts" setup>
// Modules
import { computed } from 'vue';

// Composables
import { useInput } from '@/composables/useInput';

// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';

// Types
import { Class } from '@/types/common';
import { ISwitcherProps } from '@/types/components/inputs';

// Defines

const props = withDefaults(defineProps<ISwitcherProps>(), {
  showIcon: false,
  tabindex: 0,
  disabled: false,
  label: '',
  id: '',
  small: false,
  hint: ''
});
// [END] Types and interfaces

// Composables

const { computedId } = useInput(props);

// [START] Emit
const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'switch', value: boolean): void;
}>();
// [END] Emit

// Model

const modelValue = defineModel<boolean>();

// Props

const {
  showIcon,
  tabindex,
  disabled,
  label,
  small,
  hint
} = props;

// [START] Computed
const classList = computed((): Class => {
  return [
    modelValue.value ? 'sm-switcher--on' : 'sm-switcher--off',
    { 'sm-switcher--has-icon': showIcon },
    { 'sm-switcher--disabled': disabled },
    { 'sm-switcher--small': small },
  ]
});

const iconName = computed((): string => {
  return modelValue.value ? 'Checked' : 'Cancel';
});
// [END] Computed

// [START] Methods
const handleSwitch = () => {
  if (disabled) return;

  const value = !modelValue.value;

  emits('update:modelValue', value);
  emits('switch', value);
};
// [END] Methods
</script>

<style lang="scss">
.sm-switcher {
  display: inline-flex;
  position: relative;
}

.sm-switcher__control-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sm-switcher__track {
  flex-shrink: 0;

  width: 52px;
  height: 32px;

  border-width: 2px;
  border-style: solid;
  border-radius: 100px;

  position: relative;
  z-index: 0;

  @include transition(('border-color', 'background-color'));

  &:not(.sm-switcher--disabled &) {
    cursor: pointer;
  }

  &:focus-visible {
    outline: none;
  }
}

.sm-switcher__control {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;

  @include transition(('width', 'height', 'background-color', 'transform'));

  &::before {
    content: '';
    display: none;

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;

    border-radius: 50%;
    transform: translate(-50%, -50%);

    pointer-events: none;
  }
}

.sm-switcher__label {
  @include mini;

  &:not(.sm-switcher--disabled &) {
    cursor: pointer;
  }
}

// OFF -------------------------------------------------------------------------

.sm-switcher--off {
  &:hover:not(.sm-switcher--disabled) {
    .sm-switcher__control::before {
      display: block;
      opacity: .15;
    }
  }

  &:focus:not(.sm-switcher--disabled) {
    .sm-switcher__control::before {
      display: block;
      opacity: .25;
    }
  }

  &:active:not(.sm-switcher--disabled) {
    .sm-switcher__control {
      width: 28px;
      height: 28px;

      &::before {
        display: block;

        width: 28px;
        height: 28px;

        opacity: .25;
      }
    }
  }
}

.sm-switcher--off .sm-switcher__track {
  border-color: var(--Elm50);
  background-color: var(--Secondary);
}

.sm-switcher--off .sm-switcher__control {
  width: 16px;
  height: 16px;

  background-color: var(--Elm50);

  transform: translate(-110%, -50%);

  &::before {
    display: none;

    width: 16px;
    height: 16px;

    border: calc(40px - 28px - 2px) solid var(--Secondary);
  }
}

.sm-switcher--off .sm-switcher__icon {
  color: var(--Secondary);
}

// ON --------------------------------------------------------------------------

.sm-switcher--on {
  &:hover:not(.sm-switcher--disabled) {
    .sm-switcher__control::before {
      display: block;
      opacity: .1;
    }
  }

  &:focus:not(.sm-switcher--disabled) {
    .sm-switcher__control::before {
      display: block;
      opacity: .35;
    }
  }

  &:active:not(.sm-switcher--disabled) {
    .sm-switcher__control {
      width: 28px;
      height: 28px;

      &::before {
        display: block;

        width: 28px;
        height: 28px;

        opacity: .35;
      }
    }
  }
}

.sm-switcher--on .sm-switcher__track {
  border-color: var(--Primary);
  background-color: var(--Primary);
}

.sm-switcher--on .sm-switcher__control {
  width: 24px;
  height: 24px;

  background-color: var(--OnPrimary);

  transform: translate(-10%, -50%);

  &::before {
    width: 24px;
    height: 24px;

    border: calc(40px - 28px - 2px) solid var(--Primary);
    background-color: var(--OnPrimary);
  }
}

.sm-switcher--on .sm-switcher__icon {
  color: var(--Primary);
}

// HAS ICON --------------------------------------------------------------------

.sm-switcher--has-icon {
  .sm-switcher__control {
    width: 24px;
    height: 24px;
  }

  &.sm-switcher--off .sm-switcher__control {
    transform: translate(-90%, -50%);
  }
}

// DISABLED --------------------------------------------------------------------

.sm-switcher--disabled.sm-switcher--off .sm-switcher__track {
  background-color: var(--PrimaryDisable);
  border-color: var(--PrimaryDisable);
}

.sm-switcher--disabled.sm-switcher--on .sm-switcher__track {
  background-color: var(--PrimaryDisable);
  border-color: var(--PrimaryDisable);
}

.sm-switcher--disabled .sm-switcher__control {
  background-color: var(--OnPrimaryDisable);
}

.sm-switcher--disabled .sm-switcher__icon {
  color: var(--PrimaryDisable);
}

.sm-switcher--disabled .sm-switcher__label {
  color: var(--Disabled);
}

// SMALL -----------------------------------------------------------------------

.sm-switcher--small .sm-switcher__track {
  width: 30px;
  height: 19px;
}

.sm-switcher--small.sm-switcher--off .sm-switcher__control {
  width: 11px;
  height: 11px;

  &::before {
    width: 11px;
    height: 11px;
    border-width: calc(25px - 11px - 2px);
  }
}

.sm-switcher--small.sm-switcher--off:active:not(.sm-switcher--disabled) .sm-switcher__control {
  width: 11px;
  height: 11px;

  &::before {
    width: 11px;
    height: 11px;
  }
}

.sm-switcher--small.sm-switcher--on .sm-switcher__control {
  width: 15px;
  height: 15px;

  &::before {
    width: 15px;
    height: 15px;
    border-width: calc(25px - 15px - 2px);
  }
}

.sm-switcher--small.sm-switcher--on:active:not(.sm-switcher--disabled) .sm-switcher__control {
  width: 15px;
  height: 15px;

  &::before {
    width: 15px;
    height: 15px;
  }
}

.sm-switcher__hint {
  @include micro;
  color: var(--OnSurfaceAdditional);

  position: absolute;
  top: calc(100% + 4px);
  left: 0;

  margin: 0;
  padding-inline: 24px;

  .sm-switcher--disabled & {
    color: var(--Disabled);
  }
}
</style>
