<template>
  <div class="sm-profile-dropdown">
    <div class="sm-profile-dropdown__title">
      <div class="sm-profile-dropdown__user-info">
        <div class="sm-profile-dropdown__icon-container">
          <sm-icon name="User" />
        </div>
        <span class="sm-profile-dropdown__username">{{ userName }}</span>
      </div>
    </div>
    <ul class="sm-profile-dropdown__menu">
      <li v-for="(item, index) in items" :key="index" class="sm-profile-dropdown__menu-item" @click="handleItemClick(item)">
        <div class="sm-profile-dropdown__menu-item-content">
          <sm-icon class="sm-profile-dropdown__menu-icon" :name="item.icon" />
          <span class="sm-profile-dropdown__menu-title">{{ item.title }}</span>
        </div>
        <slot name="item" :item="item"></slot>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
// Vue
import { withDefaults, defineProps, defineEmits } from 'vue';
// Types
import { IDropdownMenuItemProps, IDropdownMenuProps } from '@/types/components/headerMenu';
// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';

const props = withDefaults(defineProps<IDropdownMenuProps>(), {
  userName: '',
  items: () => []
});

const { userName, items } = props;

const emits = defineEmits<{
  (e: 'click', value: IDropdownMenuItemProps): void;
}>();


const handleItemClick = (item: IDropdownMenuItemProps): void => {
  emits('click', item);
};

</script>

<style scoped lang="scss">
.sm-profile-dropdown {
  width: 248px;
  position: absolute;
  max-width: 248px;
  right: 0;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px #3C507714;
  background: var(--Background);
}

.sm-profile-dropdown__title {
  margin-bottom: var(--margin);
}

.sm-profile-dropdown__menu-item {
  padding: var(--padding-s) var(--padding);
  display: flex;
  justify-content: space-between;
  cursor: pointer; 
  transition: background-color 0.3s ease;
}

.sm-profile-dropdown__user-info {
  padding: var(--padding-s) var(--padding);
  display: flex;
  gap: var(--gap-s);
}

.sm-profile-dropdown__menu-item-content {
  display: flex;
  gap: var(--gap-s);
}

.sm-profile-dropdown__menu-item:hover {
  background-color: var(--SelectionPrimary);
}

.sm-profile-dropdown__icon-container {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--Secondary);
  border-radius: 50%;
}

.sm-profile-dropdown__menu {
  border-top: 1px solid var(--BorderMinor);
  padding: 0;
}

.sm-profile-dropdown__menu-icon {
  color: var(--Icon);
}

</style>
