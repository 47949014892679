<template>
  <div class="ui-kit">
    <aside class="ui-kit__sidebar">
      <sm-sidebar-menu
        v-model="isOpenMenu"
        :menu="menu"
        :show-icons="false"
        :close-after-transition="false"
      ></sm-sidebar-menu>
    </aside>

    <section class="ui-kit__page-container">
      <header class="ui-kit__header">
        <sm-breadcrumbs
          v-if="isMainPage"
          :items="breadcrumbs"
          class="ui-kit__breadcrumbs"
        ></sm-breadcrumbs>

        <sm-header
          :is-main-page="isMainPage"
          :menu-items="childrenMenu"
        >
          <button
            class="ui-kit__theme-toggler"
            @click="toggleTheme"
          >
            {{ toThemeName }}
          </button>
        </sm-header>
      </header>

      <main class="ui-kit__main">
        <router-view></router-view>
      </main>
    </section>
  </div>
</template>

<script lang="ts" setup>
// Modules
import { ref, computed } from 'vue';

// Composables
import { useRoute } from 'vue-router';
import { useTheme } from '@/composables/useTheme';

// Components
import SmSidebarMenu from '@/components/layouts/SmSidebarMenu.vue';
import SmHeader from '@/components/layouts/SmHeader.vue';
import SmBreadcrumbs from '@/components/common/SmBreadcrumbs/SmBreadcrumbs.vue';

// Types
import { IBreadcrumbItem } from '@/types/components/breadcrumbs';
import { ISidebarItem } from '@/types/components/sidebar';
import { IHeaderMenuItemProps } from '@/types/components/headerMenu';

// Composable functions

const { appTheme, toggleAppTheme } = useTheme();
const route = useRoute();

// Data

const isOpenMenu = ref<boolean>(true);
const menu = <ISidebarItem[]>[
  {
    title: 'Цветовая система',
    route: { name: 'UIKit.Colors' }
  },
  {
    title: 'Типографика',
    route: { name: 'UIKit.Typography' }
  },
  {
    title: 'Иконки',
    route: { name: 'UIKit.Icons' }
  },
  {
    title: 'Кнопки',
    route: { name: 'UIKit.Buttons' }
  },
  {
    title: 'Поля ввода',
    route: { name: 'UIKit.Inputs' }
  },
  {
    title: 'Всплывающие элементы',
    route: { name: 'UIKit.Float' }
  },
  {
    title: 'Раскладка',
    route: { name: 'UIKit.Layout' }
  },
  {
    title: 'Прочее',
    route: { name: 'UIKit.Misc' }
  },
  {
    title: 'Генераторы',
    route: { name: 'UIKit.Generators' }
  },
]

// Computed

const toThemeName = computed((): string => {
  if (appTheme.value === 'light') {
    return 'Темная тема';
  }

  return 'Светлая тема';
});

const childrenMenu = computed((): IHeaderMenuItemProps[] => {
  console.log('route.matched', route.matched);
  if (route.matched?.length > 1) {
    return route.matched[1].children?.map((route) => (
      {
        title: route.meta?.title || '',
        route: { name: route.name }
      }
    )) || []
  }

  return [];
})

const isMainPage = computed<boolean>(() => !!childrenMenu.value?.length);

const breadcrumbs = computed((): IBreadcrumbItem[] => (
  route.matched.map((route) => ({
    title: route.meta.title as string,
    route: {
      name: route.name
    }
  }))
));

// Methods

const toggleTheme = (): void => {
  toggleAppTheme();
}
</script>

<style lang="scss" scoped>
.ui-kit__breadcrumbs {
  padding: var(--gap-s) var(--gap);
}
</style>